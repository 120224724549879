@import "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap";

:root {
  --color-text-white: #f3f3f3;
  --color-text-dark: #1d1815;

  --color-cloud: #d8d0c5;

  --color-primary: #d9c4a9;
  --color-primary-dark: #8d6f57;
  --color-secondary: #2d2217;
  --color-accent: #e2af51;
}

/* 
#d9b061
#d9c4a9
#d8d0c5
#8d6f57
#3f0d0c
*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #f3f3f3;
  color: var(--color-text-dark);
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.navbar {
  font-size: 1.8rem;
  font-weight: 200;

  background-color: var(--color-secondary);

  position: relative;
}
.navbarMobile {
  display: none;
}

.contactButton {
  border: none;
  display: inline-block;
  padding: 0.8rem;
  background-color: var(--color-accent);
  height: 5.4rem;
  width: 5.4rem;
  transition: all 0.1s ease-in-out;

  cursor: pointer;
}

.menuButton {
  border: none;
  display: inline-block;
  padding: 0.8rem;
  background-color: var(--color-secondary);
  height: 5.4rem;
  width: 100%;
  transition: all 0.1s ease-in-out;

  cursor: pointer;
}

.contactButton:hover {
  background-color: rgba(217, 176, 97, 0.8);
}

.contactButtonIcon {
  height: 2.4rem;
  width: 2.4rem;
  color: var(--color-secondary);
}
.menuButtonIcon {
  height: 2.4rem;
  width: 2.4rem;
  color: var(--color-accent);
}

.contactList {
  list-style: none;
  padding: 1.5rem 4rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3rem;
  color: var(--color-text-white);
  background-color: var(--color-accent);
}

.contactListWrapper {
  max-height: 0;
  opacity: 0;
  visibility: hidden;

  transition: all 0.5s ease-in-out;
}
.contactListWrapperOpen {
  max-height: 50rem;
  opacity: 1;
  visibility: visible;
}

.contactItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  list-style: none;

  color: var(--color-text-white);
}
.navigationMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  color: var(--color-cloud);
  gap: 2rem;
  padding: 2rem 0 4rem 0;
  overflow: hidden;

  transition: all 0.3s ease-in;
}
.navMobileWrapper {
  max-height: 0;
  opacity: 0;
  visibility: hidden;

  transition: all 0.5s ease;
}
.navMobileWrapperOpen {
  max-height: 100rem;
  opacity: 1;
  visibility: visible;
}
.navigationLinkMobile:link,
.navigationLinkMobile:visited {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-out;
  font-size: 2.4rem;
  font-weight: 600;
}
.navigationLink:link,
.navigationLink:visited {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-out;
}
.navigationLink:hover,
.navigationLink:active {
  color: var(--color-accent);
}

.textbox {
  position: absolute;
  top: 40%;
  left: 35%;
  transform: translate(-50%, -50%);
  padding: 2rem 4rem;
  color: var(--color-text-white);

  background-color: rgba(29, 24, 21, 0.5);
  animation: fadeIn 1s ease-in-out 0.5s;
  animation-fill-mode: backwards;
}

.slide-text {
  font-size: 8rem;
  font-weight: 100;
  letter-spacing: 4px;
  white-space: nowrap;
}
.slide-text-sub {
  font-size: 6rem;
  font-weight: 100;
  letter-spacing: 4px;
  text-align: right;
  transform: translateX(8rem);
  padding: 0 6rem;
  line-height: 1.2;

  background-color: rgba(29, 24, 21, 0.6);
}

.slide-text-small {
  font-size: 2.4rem;
  font-weight: 200;
  letter-spacing: 2px;
  line-height: 1.6;
  padding: 1rem 3rem;
  width: 100%;

  background-color: rgba(29, 24, 21, 0.2);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.heronav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12rem;

  position: relative;
  z-index: 100;

  background-color: var(--color-secondary);
}

.navCard {
  cursor: pointer;
  border: none;
  transform: translateY(-3rem);
}

.cardContainer {
  max-width: 30rem;

  position: relative;
}

.cardImage {
  min-width: 35rem;
  min-height: 20rem;
}

.cardTextbox {
  position: absolute;
  bottom: 2rem;
  left: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 90%;
}
.cardText {
  font-family: "Poppins", sans-serif;
  color: var(--color-text-white);
  text-shadow: 0 2px 12px rgb(0, 0, 0, 0.2);
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 500;

  position: relative;
  z-index: 10;
}

.cardText::after {
  content: "";
  display: block;
  height: 100%;
  width: 120%;
  background-color: rgba(217, 176, 97, 0.8);
  transform: skew(-12deg) translateX(-8%);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/* INTRO */

.introContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15rem 0;
}

.introTextbox {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.headingPrimary {
  color: var(--color-cloud);
  font-size: 4.4rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 0.5px;
  animation-fill-mode: backwards;
  width: 75%;
  opacity: 0;

  position: relative;
}

.headingPrimary::after {
  content: "";
  display: block;
  height: 5px;
  width: 30%;
  background-color: var(--color-accent);
  transform: skew(7deg);

  position: absolute;
  bottom: -2rem;
  left: 0;
  z-index: 0;
}

.introSub {
  color: var(--color-cloud);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.5;
  animation-fill-mode: backwards;
  opacity: 0;

  letter-spacing: 0.5px;
  padding-top: 4rem;
  width: 75%;
}
.introText {
  color: var(--color-cloud);
  font-size: 1.4rem;
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: 0.5px;
  padding-bottom: 1rem;
  width: 75%;
  animation-fill-mode: backwards;
  opacity: 0;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  gap: 2rem;
}

.introButton {
  display: inline-block;
  align-self: flex-start;
  border: none;
  padding: 1rem 2rem;
  color: var(--color-secondary);
  background-color: var(--color-accent);
  font-family: inherit;
  font-size: 1.4rem;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
  opacity: 0;
  text-decoration: none;
  white-space: nowrap;

  cursor: pointer;
}

.introButton:hover {
  background-color: rgba(217, 175, 97, 0.8);
}

.introImage {
  max-width: 60rem;
  /* flex: 0 0 50%;  */
  position: relative;
}

.subImg {
  position: absolute;
  bottom: -60%;
  right: -40%;
  transform: translate(-50%, -50%);

  display: block;
  width: 50%;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animateIn1 {
  animation: slideIn 0.6s ease-out 0.3s;
  animation-fill-mode: backwards;
  opacity: 1;
}
.animateIn2 {
  animation: slideIn 0.6s ease-out 0.6s;
  animation-fill-mode: backwards;
  opacity: 1;
}
.animateIn3 {
  animation: slideIn 0.6s ease-out 0.9s;
  animation-fill-mode: backwards;
  opacity: 1;
}
.animateIn4 {
  animation: slideIn 0.6s ease-out 1.5s;
  animation-fill-mode: backwards;
  opacity: 1;
}
.animateIn5 {
  animation: slideIn 0.6s ease-out 1.8s;
  animation-fill-mode: backwards;
  opacity: 1;
}

/* CTA */

.ctaWrapper {
  padding: 6rem 2rem;
}

.headingSmall {
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--color-secondary);
  text-align: center;
  margin-bottom: 2rem;
}

.ctaHeading {
  font-size: 3.2rem;
  font-weight: 700;
  color: var(--color-secondary);
  text-align: center;
  margin-bottom: 4rem;
}

.ctaForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ctaFormInputs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
}

.formInput {
  width: 100%;
  font-family: inherit;
  padding: 0.5rem 1rem;
}

.formInput:focus,
.formTextarea:focus {
  outline: 1px solid var(--color-secondary);
}

.formTextarea {
  font-family: inherit;
  padding: 0.5rem 1rem;
  resize: none;
}

.formButton {
  margin-top: 2rem;
  border: none;
  background-color: var(--color-secondary);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  color: var(--color-accent);
  display: inline-block;
  padding: 1rem 2rem;
  font-family: inherit;
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  transition: all 0.1s ease-out;

  cursor: pointer;
}

.formButton:hover {
  opacity: 0.9;
}

/* INTRO NEXT */

.introNextDiv {
  background-color: var(--color-cloud);
}

.introNext {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;

  padding: 8rem 2rem;
}

.introNextTextbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  flex: 0 0 50%;

  transition: all 0.8s ease-out;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(50%);
}

.headingIntroNext {
  font-size: 3rem;
  font-weight: 600;
}

.paragraphIntroNext {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 300;
  letter-spacing: 0.5px;
  width: 80%;
}

.introNextButton {
  opacity: 0;
}
.introNextButtonVisible {
  display: inline-block;
  align-self: flex-start;
  text-decoration: none;
  font-size: 1.4rem;
  border: none;
  padding: 1rem 2rem;
  color: var(--color-accent);
  background-color: var(--color-secondary);
  font-family: inherit;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
  animation: slideInRight 1s ease-out 0.5s;
  animation-fill-mode: backwards;

  cursor: pointer;
}
.introNextButtonVisible:hover {
  opacity: 0.9;
}

/* FEAT DIVIDER */

.featDivider {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8rem 0;

  background-color: var(--color-accent);
}

.featCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.featIconDiv {
  background-color: var(--color-cloud);
  padding: 3rem;
  border-radius: 50%;
}

.featIcon {
  height: 64px;
  width: 64px;
  color: var(--color-secondary);
}

.featText {
  font-size: 1.8rem;
  font-weight: 400;
  width: 60%;
  text-align: center;
}

/* STYLES SECTION */

.sectionStyles {
  background-color: var(--color-secondary);
  padding: 8rem 0;
}

.sectionHeading {
  color: var(--color-cloud);
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.5px;

  position: relative;
}
.sectionHeading::after {
  content: "";
  display: block;
  height: 5px;
  width: 10%;
  background-color: var(--color-accent);
  transform: skew(7deg);

  position: absolute;
  bottom: -1rem;
  left: 0;
  z-index: 0;
}

.sectionText {
  color: var(--color-cloud);
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.6;
  padding-top: 4rem;
}
.sectionTextSub {
  color: var(--color-cloud);
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  padding-top: 4rem;
  width: 70%;
}

.stylesContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 8rem;
  padding: 0 2rem;
}

.styleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 45rem;
}

.styleCardImg {
  max-width: 50rem;

  overflow: hidden;
  position: relative;
}

.styleCardTextbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-cloud);
  gap: 1rem;
}

.styleCardTitle {
  color: var(--color-cloud);
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem 0;
  align-self: stretch;
  text-align: center;
}

.styleCardText {
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;
}

.overlayCard {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
}
.overlayCardHidden {
  left: -100%;
  opacity: 0;
  visibility: hidden;
}

.overlayCardButton {
  border: 1px solid #d8d0c549;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  border-radius: 50%;
  transition: all 0.1s ease-out;

  cursor: pointer;
}
.overlayCardButton:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

/* SOCIAL BAR */

.socialBar {
  padding: 8rem 0;
  background-color: var(--color-accent);
}

.dividerBar {
  height: 3px;
  width: 20rem;
  background-color: var(--color-secondary);
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8rem;
}

.socialsFB,
.socialsIG {
  height: 5rem;
  width: 5rem;

  transition: all 0.2s ease-out;
}

.socialsFB:hover,
.socialsIG:hover {
  transform: scale(1.1);
}

/* GALLERY */

.gallery {
  padding: 8rem 20rem;
  background-color: var(--color-primary);
}

.galleryHeading {
  color: var(--color-secondary);
  font-size: 3.4rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.5px;
  margin-bottom: 3rem;

  position: relative;
}
.galleryHeading::after {
  content: "";
  display: block;
  height: 5px;
  width: 10%;
  background-color: var(--color-accent);
  transform: skew(7deg);

  position: absolute;
  bottom: -1rem;
  left: 0;
  z-index: 0;
}

.galleryTextbox {
  padding: 4rem 0;
  flex: 0 0 35%;
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
}

.galleryTextSub {
  color: var(--color-secondary);
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  width: 70%;
}

.galleryFlex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 4rem;

  margin-top: 8rem;
}

.gallerySubTitle {
  color: var(--color-secondary);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

.galleryGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;
}

.galleryGridImg {
  padding: 2px;
  border: 1px solid #2d22173f;
  overflow: hidden;
}
.galleryImg {
  transition: all 0.5s ease-in-out;
}
.galleryImg:hover {
  transform: scale(1.2);
}

.galleryList {
  list-style: none;
}
.galleryListItem {
  margin: 1rem 0;
  font-size: 1.6rem;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
.galleryListText {
  font-size: 1.8rem;
  font-weight: 600;
}
.galleryListIcon {
  height: 16px;
  width: 16px;
  color: green;
}

/* DIFFERENCE SECTION */

.sectionDifference {
  padding: 8rem 0;
  background-color: var(--color-secondary);
}
.differenceHeading {
  font-size: 4.4rem;
  font-weight: 600;
  color: var(--color-cloud);
  text-align: center;
  margin: 8rem 0 16rem 0;
}

.differenceGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.differenceGridText,
.differenceGridText2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6rem;
  padding: 6rem 4rem;
}
.differenceGridText2 {
  background-color: var(--color-primary);
}
.differenceGridTitle {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--color-cloud);

  position: relative;
}
.differenceGridTitle::after {
  content: "";
  display: block;
  height: 3px;
  width: 20%;
  background-color: var(--color-accent);
  position: absolute;
  bottom: -2rem;
  left: 0;
  z-index: 0;
}
.differenceGridPara {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  color: var(--color-cloud);
  width: 80%;
}
.differenceGridTitle2 {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--color-secondary);

  position: relative;
}
.differenceGridTitle2::after {
  content: "";
  display: block;
  height: 3px;
  width: 20%;
  background-color: var(--color-secondary);
  position: absolute;
  bottom: -2rem;
  left: 0;
  z-index: 0;
}
.differenceGridPara2 {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
  color: var(--color-secondary);
  width: 80%;
}

.differenceGridImg {
  min-height: 50rem;
  background-color: var(--color-secondary);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.differenceButton {
  display: inline-block;
  align-self: flex-start;
  border: none;
  padding: 1rem 2rem;
  color: var(--color-secondary);
  background-color: var(--color-accent);
  font-family: inherit;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;

  cursor: pointer;
}
.differenceButton:hover {
  opacity: 0.9;
}

/* SECTION CONTACT */

.sectionContact {
  padding-bottom: 8rem;
  background-color: var(--color-secondary);
}

.contactHeading {
  font-size: 3.4rem;
  font-weight: 700;
  color: var(--color-cloud);
  text-align: center;
  margin-bottom: 6rem;
}

.contactFlex {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 20rem;
}
.contactFormButton {
  margin-top: 2rem;
  border: none;
  background-color: var(--color-accent);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  color: var(--color-secondary);
  display: inline-block;
  padding: 1rem 2rem;
  font-family: inherit;
  width: 100%;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  transition: all 0.1s ease-out;

  cursor: pointer;
}

.contactFormButton:hover {
  opacity: 0.9;
}

.contactTextbox {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 3rem;
}

.contactRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.contactLinkHead {
  text-decoration: none;
  border: none;
  background-color: inherit;
  font-size: 2rem;
  font-family: inherit;
  font-weight: 500;
  color: var(--color-cloud);
  margin-bottom: 2rem;
  white-space: nowrap;
  transition: all 0.2s ease-out;

  cursor: pointer;
}
.contactLink {
  border: none;
  background-color: inherit;
  font-size: 1.8rem;
  font-family: inherit;
  font-weight: 300;
  color: var(--color-cloud);
  white-space: nowrap;
  text-decoration: none;
  transition: all 0.2s ease-out;

  cursor: pointer;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.copyParagraph {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--color-cloud);
}
.copyLink:link,
.copyLink:visited {
  text-decoration: none;
  font-family: inherit;
  color: var(--color-accent);
}

.contactLinkHead:hover,
.contactLink:hover {
  color: var(--color-accent);
}

/* SECTION GALLERY SHOW */

.sectionGalleryShow {
  padding: 8rem 0;
  background-color: var(--color-primary);
}

.headingGalleryShow {
  color: var(--color-secondary);
  font-size: 4.4rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 0.5px;
  margin-bottom: 8rem;

  position: relative;
}

.headingGalleryShow::after {
  content: "";
  display: block;
  height: 5px;
  width: 30%;
  background-color: var(--color-accent);
  transform: skew(7deg);

  position: absolute;
  bottom: -2rem;
  left: 0;
  z-index: 0;
}

.filtersBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.filterButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: inherit;
  border: none;
  background-color: inherit;
  color: inherit;
  font-size: 2rem;
  font-weight: 500;

  transition: all 0.3s ease-out;
  position: relative;
  z-index: 10;
  cursor: pointer;
}
.filterButton::after {
  content: "";
  display: block;
  height: 5px;
  width: 0;
  background-image: linear-gradient(
    to right,
    var(--color-accent),
    var(--color-accent)
  );

  transition: all 0.3s ease-out;
  position: absolute;
  bottom: 1.2rem;
  left: 0;
  z-index: -1;
}
.filterButton:hover::after {
  width: 100%;
}

.galleryShowSlider {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 75vh;
  position: relative;
}

.goToTopButton {
  border: none;
  background-color: var(--color-cloud);
  padding: 1rem;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  transition: all 1s ease;

  cursor: pointer;

  position: fixed;
  bottom: 4rem;
  right: 4rem;
  z-index: 999;
}
.bounce {
  animation: bounce 0.5s infinite;
}
@keyframes bounce {
  25% {
    top: 52%;
  }
  75% {
    top: 48%;
  }
}

/* 
*******************************************
*******************************************
*******************************************
*******************************************
*******************************************
******************************************* QUERIES *******************************************
*/

/* 1456px */
@media only screen and (max-width: 91em) {
  .styleCard {
    max-width: 40rem;
  }
  .introImage {
    max-width: 80rem;
  }
  .headingPrimary {
    font-size: 3.4rem;
  }
  .introTextbox {
    align-items: center;
  }
  .introContent {
    max-width: 120rem;
    margin: 0 auto;
    flex-direction: column-reverse;
    align-items: center;
    gap: 6rem;
  }
  .gallery {
    padding: 8rem;
  }
  .slide-text {
    font-size: 6rem;
  }
  .slide-text-sub {
    font-size: 5rem;
  }
  .slide-text-small {
    font-size: 2rem;
  }
}

/* 1296px */
@media only screen and (max-width: 81em) {
  html {
    font-size: 56.25%;
  }
}

/* 1148px */
@media only screen and (max-width: 72em) {
  .stylesContainer {
    flex-direction: column;
    align-items: center;
    gap: 8rem;
  }
  .styleCardImg {
    max-width: 100rem;
  }
  .styleCard {
    max-width: 100rem;
  }
  .styleCardTitle {
    font-size: 3rem;
  }
  .styleCardText {
    font-size: 2rem;
    width: 75%;
  }
  .stylesTextbox {
    margin: 8rem 0 12rem 20rem;
  }
  .sectionTextSub {
    width: 85%;
  }
  .ctaFormInputs {
    flex-direction: column;
    gap: 1rem;
  }
  .heronav {
    gap: 8rem;
  }
  .cardContainer {
    max-width: 25rem;
  }
  .cardImage {
    min-width: 25rem;
    min-height: 15rem;
  }
  .featText {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .featIconDiv {
    padding: 2rem;
  }
  .featIcon {
    height: 48px;
    width: 48px;
  }
  .differenceHeading {
    margin: 2rem 0 8rem 0;
  }
  .gallerySubTitle {
    font-size: 2.2rem;
  }
  .galleryTextSub {
    font-size: 1.6rem;
  }
  .contactTextbox {
    flex-wrap: wrap;
    gap: 4rem;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 2rem;
  }
  .galleryShowSlider {
    max-height: 50vh;
    margin: 1rem;
    background-position: bottom;
  }
}

/* 1024px */
@media only screen and (max-width: 64em) {
  .subImg {
    width: 40%;
    bottom: -50%;
    right: -20%;
  }
  .galleryFlex {
    flex-direction: column;
  }
  .differenceGridTitle2 {
    font-size: 2rem;
  }
  .differenceGridPara2 {
    font-size: 1.6rem;
  }
  .heronav {
    gap: 4rem;
  }
  .slide-text {
    font-size: 5rem;
  }
  .slide-text-sub {
    font-size: 4rem;
  }
  .slide-text-small {
    font-size: 1.8rem;
  }
  .stylesTextbox {
    margin: 8rem auto 12rem 4rem;
  }
  .contactFlex {
    margin: 0;
  }
}

/* 848px */
@media only screen and (max-width: 53em) {
  html {
    font-size: 50%;
  }
  .introNext {
    flex-direction: column-reverse;
  }
  .headingIntroNext {
    font-size: 2.6rem;
  }
  .paragraphIntroNext {
    font-size: 1.8rem;
  }
  .featDivider {
    flex-direction: column;
    gap: 4rem;
  }
  .featCard {
    gap: 2rem;
  }
  .featText {
    font-size: 2.4rem;
  }
  .featIcon {
    height: 64px;
    width: 64px;
  }
  .featIconDiv {
    padding: 3rem;
  }
  .galleryHeading {
    font-size: 2.6rem;
  }
  .galleryTextSub {
    font-size: 1.8rem;
  }
  .subImg {
    bottom: -35.6%;
  }
  .goToTopButton {
    height: 5rem;
    width: 5rem;
  }
}

/* 672px */
@media only screen and (max-width: 42em) {
  .cardContainer {
    max-width: 20rem;
  }
  .cardImage {
    min-width: 20rem;
    min-height: 12rem;
  }
  .cardText {
    font-size: 1.4rem;
  }
  .introImage {
    margin: 0 2rem;
    max-width: 60rem;
  }
  .differenceGrid {
    grid-template-columns: 1fr;
  }
  #gridCell2 {
    order: 1;
  }
  #gridCell1 {
    order: 2;
  }
  #gridCell3 {
    order: 3;
  }
  #gridCell4 {
    order: 4;
  }
  #gridCell6 {
    order: 5;
  }
  #gridCell5 {
    order: 6;
  }
  .navigation {
    display: none;
  }
  .contactButton {
    width: 100%;
  }
  .contactButton:hover {
    background-color: rgba(217, 176, 97, 1);
  }
  .contactList {
    justify-content: center;
  }
  .contactItem {
    font-weight: 500;
  }
  .navbarMobile {
    display: block;
  }
  .textbox {
    left: 45%;
  }
  .slide-text {
    font-size: 4.4rem;
  }
  .slide-text-sub {
    font-size: 3rem;
    line-height: 1.7;
  }
  .slide-text-small {
    font-size: 1.6rem;
    line-height: 1.7;
  }
  .heronav {
    flex-direction: column;
    gap: 1rem;
    position: relative;
  }
  .heronav::after {
    content: "";
    display: block;
    height: 3px;
    width: 90%;
    background-color: #e2af5186;

    position: absolute;
    bottom: -10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cardContainer {
    max-width: 50rem;

    position: relative;
  }
  .cardImage {
    min-width: 50rem;
    min-height: 20rem;
  }
  .dividerBar {
    width: 12rem;
  }
  .differenceHeading {
    font-size: 3.4rem;
  }
}

/* 544px */
@media only screen and (max-width: 34em) {
  .textbox {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .slide-text {
    font-size: 3.4rem;
  }
  .slide-text-sub {
    font-size: 2rem;
    line-height: 1.7;
    transform: translateX(0);
    white-space: nowrap;
  }
  .slide-text-small {
    font-size: 1.4rem;
    line-height: 1.7;
  }
  .cardContainer {
    max-width: 40rem;

    position: relative;
  }
  .cardImage {
    min-width: 40rem;
    min-height: 20rem;
  }
  .contactItem {
    font-size: 1.4rem;
    white-space: nowrap;
  }
  .socials {
    gap: 3rem;
  }
  .dividerBar {
    width: 10rem;
    height: 2px;
  }
  .galleryHeading {
    font-size: 2.2rem;
  }
  .galleryTextSub {
    font-size: 1.6rem;
  }
  .gallerySubTitle {
    font-size: 2.2;
  }
  .gallery {
    padding: 2rem;
  }
  .galleryContent {
    padding: 6rem 0;
  }
  .copyParagraph {
    padding: 0 1rem;
    text-align: center;
  }
  .styleCardTitle {
    font-size: 2.2rem;
  }
  .styleCardText {
    font-size: 1.6rem;
  }
}
